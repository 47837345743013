export const initialExportState = {
  exportParams: {},
  exportList: [],
  showExports: false,
};

// ==============================|| CORE REDUCER ||============================== //

const exportReducer = (state = initialExportState, action) => {
  switch (action.type) {
    case "exportParams":
      return {
        ...state,
        exportParams: action.value,
      };
    case "exportList":
      return {
        ...state,
        exportList: action.value,
      };
    case "showExports":
      return {
        ...state,
        showExports: action.value,
      };
    default:
      return state;
  }
};

export default exportReducer;
