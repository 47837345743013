import React, { useState } from "react";
import { useHistory, useRouteMatch, Link } from "react-router-dom";
import {
  ComposableMap,
  Geographies,
  Geography,
  Annotation,
  Graticule,
  Sphere,
} from "react-simple-maps";
import { Modal, Segment, Image, Button, Header, Card } from "semantic-ui-react";
import conabLogo from "../../img/conab-logo.png";
import intaLogo from "../../img/inta-argentina.png";
import magypLogo from "../../img/magyp-argentina.svg";
import iniaLogo from "../../img/inia-chile.png";
import maliLogo from "../../img/GLAM2-Logo-Mali.png";
import icpacLogo from "../../img/icpac.svg";

import { Trans } from "@lingui/macro";

const interfaces = ["AR", "BR", "CL", "ML"];

const icpacNations = [
  "DJ",
  "ET",
  "SO",
  "-99",
  "ER",
  "SD",
  "SS",
  "KE",
  "UG",
  "TZ",
  "BI",
  "RW",
];

const geoUrl =
  "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";

export const InterfaceMap = () => {
  const [open, setOpen] = useState(false);
  const [geo, setGeo] = useState(false);
  // const history = useHistory();
  // let match = useRouteMatch();

  const handleClick = (geo, isInterface) => () => {
    console.log(geo);
    if (isInterface) {
      setOpen(true);
      setGeo(geo);
    }
  };

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <div>
      <ComposableMap
        height={400}
        projection="geoStereographic"
        projectionConfig={{
          rotate: [25.0, 17, -0],
          scale: 475,
        }}
      >
        <Sphere stroke="#FF5533" strokeWidth={2} />
        <Graticule stroke="#EAEAEC" />
        <Geographies
          geography={geoUrl}
          fill="#D6D6DA"
          stroke="#FFFFFF"
          strokeWidth={0.5}
        >
          {({ geographies }) =>
            geographies.map((geo) => {
              const isInterface =
                interfaces.indexOf(geo.properties.ISO_A2) !== -1 ||
                icpacNations.indexOf(geo.properties.ISO_A2) !== -1;
              // const isActive = geo.properties.ISO_A2 === match.path.split('/')[1].toUpperCase()
              // const isICPAC = match.path.split('/')[1] === 'icpac' && icpacNations.includes(geo.properties.ISO_A2)
              const isActive = "";
              const isICPAC = "";
              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  fill={
                    isActive
                      ? "#fbbd08"
                      : isICPAC
                      ? "#fbbd08"
                      : isInterface
                      ? "#2185d0"
                      : "#D6D6DA"
                  }
                  strokeWidth={isInterface ? 1 : 0.5}
                  onClick={handleClick(geo.properties, isInterface)}
                />
              );
            })
          }
        </Geographies>
        <Annotation
          subject={[2.3522, 48.8566]}
          dx={-90}
          dy={-30}
          connectorProps={{
            stroke: "#FF5533",
            strokeWidth: 3,
            strokeLinecap: "round",
          }}
        >
          <text x="-8" textAnchor="end" alignmentBaseline="middle" fill="#F53">
            {"Paris"}
          </text>
        </Annotation>
        <Modal
          size="tiny"
          dimmer="inverted"
          open={open}
          onClose={closeModal}
          style={{ width: "fit-content" }}
        >
          <Segment>
            <Header textAlign="center">
              <Trans>Click Logo to Launch Interface</Trans>
            </Header>
            {geo.ISO_A2 === "AR" ? (
              <Card.Group itemsPerRow={1}>
                {/* <Card href="/ar/magyp">
                  <Card.Content>                    
                    <Image                      
                      src={magypLogo}
                    />
                  </Card.Content>
                </Card> */}
                <Card href="/ar/inta">
                  <Card.Content>
                    <Image
                      style={{ marginLeft: "5em" }}
                      size="tiny"
                      src={intaLogo}
                    />
                  </Card.Content>
                </Card>
              </Card.Group>
            ) : geo.ISO_A2 === "BR" ? (
              <Card href="/br/conab">
                <Card.Content>
                  <Image src={conabLogo} />
                </Card.Content>
              </Card>
            ) : geo.ISO_A2 === "CL" ? (
              <Card centered href="/cl/inia" style={{ width: "fit-content" }}>
                <Card.Content>
                  <Image size="small" src={iniaLogo} />
                </Card.Content>
              </Card>
            ) : geo.ISO_A2 === "ML" ? (
              <Card href="/ml/mali">
                <Card.Content>
                  <Image src={maliLogo} />
                </Card.Content>
              </Card>
            ) : icpacNations.includes(geo.ISO_A2) ? (
              <Card
                centered
                href="/icpac"
                style={{ width: "fit-content", backgroundColor: "#0c793f" }}
              >
                <Card.Content>
                  <Image size="small" src={icpacLogo} />
                </Card.Content>
              </Card>
            ) : null}
          </Segment>
        </Modal>
      </ComposableMap>
    </div>
  );
};
