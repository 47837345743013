import { createTheme } from '@mui/material/styles';

const today = new Date();

export const initialDashboardState = {
    items: [
        // {
        //     i: "n" + 0,
        //     x: 8 % 20,
        //     y: 0, // puts it at the bottom
        //     w: 2,
        //     h: 2
        // }
    ],
};

// ==============================|| CORE REDUCER ||============================== //

const dashboardReducer = (state = initialDashboardState, action) => {
    switch (action.type) {
        case "items":
            return {
                ...state,
                items: action.value
            };
        default:
            return state;
    }
};

export default dashboardReducer;