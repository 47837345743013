import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { store } from "./store/index";

import Routes from "./components/Routes";
import { Initialize } from "./components/Utils/Utils";
// import reportWebVitals from './reportWebVitals';
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "react-reflex/styles.css";
import "./css/fonts.css";
import "./css/glam.css";
import "./css/datepicker.css";
import "@fontsource/roboto";

import ReactDOM from "react-dom";

import { BrowserRouter } from "react-router-dom";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

const AppWrapper = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Initialize />
          <Routes />
        </LocalizationProvider>
      </BrowserRouter>
    </Provider>
  );
};

ReactDOM.render(<AppWrapper />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
