import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Bar } from "./Core/Bar";
import { Sidebar, MobileNav } from "./Core/Sidebar";
import HelpBar from "./Core/HelpBar";

import { Outlet } from "react-router-dom";

import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import CircularProgress from "@mui/material/CircularProgress";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import useMediaQuery from "@mui/material/useMediaQuery";
import DownloadIcon from "@mui/icons-material/Download";

import { Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";

import { InitialLoading } from "./Utils/Utils";
import { getLang, dynamicActivate } from "./Utils/i18n";

const Export = ({ id }) => {
  const exportState = useSelector((state) => state.export);
  const coreState = useSelector((state) => state.core);
  const [exportLoading, setExportLoading] = useState(true);
  const [exportLoadingCounter, setExportLoadingCounter] = useState(0);
  const [downloadURL, setDownloadURL] = useState(null);
  let exportURL = `${coreState.apiURL}exports/`;

  const downloadExport = () => {
    const link = document.createElement("a");
    // link.download = `download.json`;
    link.href = downloadURL;
    link.click();
  };

  const getExportStatus = () => {
    fetch(exportURL + id)
      .then((response) => response.json())
      .then((json) => {
        console.log(json["completed"]);
        if (json["completed"] != null) {
          console.log("here");
          setExportLoading(false);
          setDownloadURL(json["file_object"]);
        } else {
          console.log("there");
          setTimeout(() => {
            setExportLoadingCounter(exportLoadingCounter + 1);
          }, 20000);
        }
      });
  };

  useEffect(() => {
    getExportStatus();
    console.log("asdasd");
  }, [exportLoadingCounter]);

  return (
    <TableRow key={id}>
      <TableCell>{id}</TableCell>
      <TableCell align="center">
        {exportLoading ? (
          <CircularProgress color="success" size={20} />
        ) : (
          <CheckCircleOutlineIcon color="success" />
        )}
      </TableCell>
      <TableCell align="center">
        {downloadURL ? (
          <IconButton color="primary" onClick={downloadExport}>
            <DownloadIcon />{" "}
          </IconButton>
        ) : null}
      </TableCell>
    </TableRow>
  );
};

const ExportMonitor = () => {
  const coreState = useSelector((state) => state.core);
  const exportState = useSelector((state) => state.export);
  const dispatch = useDispatch();
  let exportURL = `${coreState.apiURL}exports/`;

  const [exportBar, setExportBar] = useState(false);
  const [exportLoading, setExportLoading] = useState(true);
  const [exportLoadingCounter, setExportLoadingCounter] = useState(0);

  const openDialog = () => {
    dispatch({ type: "showExports", value: true });
  };

  const action = (
    <Stack direction="row">
      <IconButton onClick={openDialog} color="info">
        <FullscreenIcon />
      </IconButton>
      <IconButton
        color="error"
        onClick={() => {
          setExportBar(false);
        }}
      >
        <CloseIcon />
      </IconButton>
    </Stack>
  );

  const getExportStatus = async () => {
    let exportStatus = exportState.exportList.map(async (e) => {
      let r = await fetch(exportURL + e.exportID)
        .then((response) => response.json())
        .then((json) => {
          return json["completed"];
        });
      return r;
    });
    let data = await Promise.all(exportStatus);
    let loading = data.some((e) => e == null);
    console.log(data, loading);
    return loading;
  };

  useEffect(async () => {
    console.log(exportURL);
    console.log(exportState.exportList);
    if (exportState.exportList.length > 0 && !exportState.showExports) {
      setExportBar(true);
    } else {
      setExportBar(false);
    }
    let x = await getExportStatus();
    setExportLoading(x);
  }, [exportState]);

  useEffect(() => {
    console.log(exportLoading);
    if (exportLoading) {
      setTimeout(async () => {
        console.log("hi");
        let s = await getExportStatus();
        setExportLoading(s);
        setExportLoadingCounter(exportLoadingCounter + 1);
      }, 20000);
    } else {
      console.log("not loading");
    }
  }, [exportLoading, exportLoadingCounter]);
  return (
    <Snackbar
      open={exportBar}
      message={
        <Box>
          Current Exports ({exportState.exportList.length})
          <IconButton>
            {exportLoading ? (
              <CircularProgress color="success" size={20} />
            ) : (
              <CheckCircleOutlineIcon color="success" />
            )}
          </IconButton>
        </Box>
      }
      action={action}
    />
  );
};

const ExportDialog = () => {
  const coreState = useSelector((state) => state.core);
  const exportState = useSelector((state) => state.export);
  const dispatch = useDispatch();

  const closeDialog = () => {
    dispatch({ type: "showExports", value: false });
  };

  return (
    <Dialog onClose={closeDialog} open={exportState.showExports}>
      <DialogTitle>
        <Trans>Current Exports</Trans>
      </DialogTitle>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Trans>Export ID</Trans>
              </TableCell>
              <TableCell align="center">
                <Trans>Status</Trans>
              </TableCell>
              <TableCell align="center">
                <Trans>Download</Trans>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {exportState.exportList.map((exp) => (
              <Export id={exp.exportID} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Dialog>
  );
};

const Base = ({ handleLanguageChange }) => {
  const state = useSelector((state) => state.core);
  const exportState = useSelector((state) => state.export);
  const dispatch = useDispatch();
  const matches = useMediaQuery(state.theme.breakpoints.up("md"));

  useEffect(() => {
    let userLanguage = getLang();
    console.log(userLanguage);
    if (userLanguage !== undefined) {
      dispatch({ type: "language", value: userLanguage });
      // dynamicActivate(userLanguage)
    }
  }, []);

  useEffect(() => {
    dynamicActivate(state.language);
    console.log(state.language);
  }, [state.language]);

  return (
    <ThemeProvider theme={state.theme}>
      <I18nProvider i18n={i18n}>
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Bar />
          {matches ? <Sidebar /> : <MobileNav />}

          <HelpBar />
          {state.primed ? <Outlet /> : <InitialLoading />}
          <ExportMonitor />
          <ExportDialog />
        </Box>
      </I18nProvider>
    </ThemeProvider>
  );
};

export default Base;
