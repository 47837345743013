import { i18n } from "@lingui/core";
// import { en, cs } from 'make-plural/plurals'

export const locales = ["en", "es", "pt-BR"];
export const defaultLocale = "en";

// i18n.loadLocaleData({
//   en: { plurals: en },
//   cs: { plurals: cs },
// })

/**
 * We do a dynamic import of just the catalog that we need
 * @param locale any locale string
 */
export async function dynamicActivate(locale) {
  const { messages } = await import(`../../locales/${locale}/messages`);
  i18n.load(locale, messages);
  i18n.activate(locale);
}

export function getLang() {
  if (navigator.languages !== undefined) {
    console.log(navigator.languages);
    for (const l of navigator.languages) {
      let lstring = l;
      if (locales.includes(lstring)) {
        return lstring;
      }
    }
  }
}
