import React, { useEffect, useState, useRef } from "react";
import * as Space from "react-spaces";
// import { useStore } from "../../Store";
import { Icon, Button, Popup, Header } from "semantic-ui-react";

import { Trans } from "@lingui/macro";

export const TourStep = ({ step, position, message }) => {
  const exitTour = () => {
    // dispatch({ type: "tourState", value: 0 });
  };

  const nextTour = () => {
    // dispatch({ type: "tourState", value: state.tourState + 1 });
  };

  const backTour = () => {
    // dispatch({ type: "tourState", value: state.tourState - 1 });
  };

  const endTour = () => {
    // dispatch({ type: "tourState", value: 0 });
  };

  return (
    <Popup
      content={
        <div>
          <Header as="h4">
            <Trans>Welcome to GLAM 2.0</Trans>
          </Header>
          <p style={{ fontWeight: 600 }}>
            {/* {state.tourState}. {message} */}
          </p>
          <Button
            size="tiny"
            icon
            labelPosition="left"
            onClick={backTour}
            // disabled={state.tourState === 1}
          >
            {" "}
            <Icon name="arrow left" />
            <Trans>Back</Trans>
          </Button>
          <Button
            size="tiny"
            icon="close"
            content={<Trans>Exit</Trans>}
            negative
            onClick={exitTour}
          />
          {/* {state.tourState < 12 ? (
            <Button
              size="tiny"
              icon
              labelPosition="right"
              primary
              onClick={nextTour}
            >
              <Icon name="arrow right" />
              <Trans>Next</Trans>
            </Button>
          ) : (
            <Button
              size="tiny"
              icon="check"
              content={<Trans>Done!</Trans>}
              positive
              onClick={endTour}
            />
          )} */}
        </div>
      }
      flowing
      // open={state.tourState === step}
      trigger={<p />}
      position={position}
    />
  );
};
